import { post } from '@/utils/request'

//获取目录 物料列表
export function getSpList(data) {
    return post('edu/tool/list', data)
}
//添加物料
export function addSpType(data) {
    return post('edu/Tool/addTool', data)
}
//物料回显
export function getInfo(data) {
    return post('edu/tool/info', data)
}

//物料编辑
export function editSpType(data) {
    return post('edu/tool/edit', data)
}
//物料删除
export function delSpType(data) {
    return post('edu/tool/delType', data)
}
