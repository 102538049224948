import { post } from '@/utils/request'

//获取目录
export function getServiceList(data) {
    return post('edu/Edu_Type/getTypeList', data)
}
//添加教务类型
export function addServiceType(data) {
    return post('/edu/Edu_Type/addType', data)
}
//教务类型回显
export function getInfo(data) {
    return post('/edu/Edu_Type/getTypeInfo', data)
}

//教务类型编辑
export function editServiceType(data) {
    return post('/edu/edu_type/editType', data)
}
//教务类型删除
export function delServiceType(data) {
    return post('/edu/edu_type/delType', data)
}
// 不可拆分服务类型列表
export function noSplitTypeList(data) {
    return post('/edu/edu_type/getType0', data)
}


