<template>
  <div>
    <el-card>
      <all-log :dialogLog="dialogLog" />

      <div class="menu-box">
        <h2>物料管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-button type="primary" @click="pAdd()">添加物料</el-button>
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column prop="name" label="物料名称"> </el-table-column>
            <el-table-column label="所属服务类型">
              <template v-slot="scope">
                <el-tag
                  :key="index"
                  size="mini"
                  style="margin-right:3px"
                  v-for="(item, index) in scope.row.type_name"
                  >{{ item }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column prop="class_name" label="物料分类">
            </el-table-column>

            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 0">
                  禁用
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="formRules" ref="formRef" :model="form">
        <el-form-item
          prop="class"
          label="物料分类"
          :label-width="formLabelWidth"
        >
          <el-select
            v-model="form.class"
            style="width: 100%"
            placeholder="请选择物料分类"
            clearable
          >
            <el-option
              v-for="item in spcateList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="name"
          label="物料名称"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请填写物料名称"
            @change="handleChange"
            v-model="form.name"
          ></el-input>
        </el-form-item>

        <el-form-item
          prop="type_id"
          label="服务类型"
          :label-width="formLabelWidth"
        >
          <el-select
            multiple
            v-model="form.type_id"
            style="width: 100%"
            placeholder="请选择服务类型"
            @change="handleChange"
            clearable
          >
            <el-option
              v-for="item in serviceList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item
          v-if="addOrEdit === 2"
          prop="sort"
          label="排序"
          :label-width="formLabelWidth"
        >
          <el-input @change="handleChange" v-model="form.sort"></el-input>
        </el-form-item> -->

        <!-- <el-form-item
          prop="must"
          label="是否必备"
          :label-width="formLabelWidth"
        >
          <el-radio-group @input="handleChange" v-model="form.must">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">必备</el-radio>
            <el-radio :label="2">全部必备</el-radio>
          </el-radio-group>
        </el-form-item> -->

        <el-form-item
          v-if="addOrEdit === 2"
          prop="status"
          label="状态"
          :label-width="formLabelWidth"
        >
          <el-switch
            @change="handleChange"
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" @click="confirmAE()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSpList,
  addSpType,
  getInfo,
  editSpType,
  delSpType,
} from '@/api/edu/supplies.js'
import { getScList } from '@/api/edu/suppliescate.js'
import { getServiceList } from '@/api/edu/servicetype.js'
export default {
  data() {
    return {
      dialogLog: {
        state: false,
      },
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      spcateList: [],
      serviceList: [],
      query: {
        page: 1,
        page_code: '',
        limit: 7,
      },
      dialogVisible: false,
      changeDialogVisible: false,
      formRules: {
        name: {
          required: true,
          message: '请输入物料名称',
          trigger: 'blur',
        },
        // sort: { required: true, message: '请输入物料列表排序' },
        class: { required: true, message: '请选择物料分类' },
        // type_id: { required: true, message: '请选择服务类型' },
      },
      title: '添加物料',
      addOrEdit: 1,
      formLabelWidth: '120px',
      optionsTask: [],
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      form: {
        name: '',
        class: '',
        type_id: [],
      },
      tableData: [],
    }
  },
  created() {
    this.getList()
    this.getOptionList()
  },
  methods: {
    async getOptionList() {
      const { data: res } = await getScList()
      console.log(res)
      if (res.code === 1) {
        this.spcateList = res.data
      } else {
        this.$message.error('获取物料分类数据失败！')
      }
      const { data: res1 } = await getServiceList()
      if (res.code === 1) {
        this.serviceList = res1.data
      } else {
        this.$message.error('获取服务类型数据失败！')
      }
    },
    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.limit = val
      this.getList()
    },
    async getList() {
      this.tableLoading = true
      const { data: res } = await getSpList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data
        this.exp.total = res.exp.num
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancelAE() {
      this.title = '添加物料'
      this.addOrEdit = 1
      this.isEdit = false

      this.form = {
        name: '',
        class: '',
        type_id: [],
      }
      setTimeout(() => {
        this.$refs.formRef.clearValidate()
      }, 30)
      this.dialogVisible = false
    },

    // 新增打开
    pAdd(row) {
      console.log(row)

      this.dialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      this.dialogVisible = true
      this.title = '编辑物料'
      this.addOrEdit = 2
      const { data: res } = await getInfo({
        id: row.id,
      })

      // this.form = res.data
      //  this.form.type_id = res.data.type_id
      if (res.data.type_id !== null) {
        let length = res.data.type_id.length
        let type = res.data.type_id
        if (type.length !== 0) {
          for (let i = 0; i < length; i++) {
            type[i] = Number(type[i])
          }
        }
        this.form.type_id = type
      } else {
        this.form.type_id = []
      }

      this.form.name = res.data.name
      this.form.id = row.id
      this.form.status = res.data.status
      this.form.class = Number(res.data.class)
    },
    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await delSpType({
            id: row.id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    confirmAE(e) {
      console.log(e)
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加
            let query = {}
            query.type_id = JSON.stringify(this.form.type_id)
            query.name = this.form.name
            query.class = this.form.class
            const { data: res } = await addSpType(query)
            if (res.code === 1) {
              this.$message.success('添加成功')
              this.getList()
              this.cancelAE()
            } else {
              this.$message.error(res.message)
            }
          } else {
            // 编辑

            if (this.isEdit === true) {
              let query = {}
              query.type_id = JSON.stringify(this.form.type_id)
              query.name = this.form.name
              query.class = this.form.class
              query.status = this.form.status
              query.id = this.form.id
              const { data: res } = await editSpType(query)
              if (res.code === 1) {
                this.$message.success('编辑成功')
                this.cancelAE()
                this.getList()
              } else {
                this.$message.error(res.message)
              }
            } else {
              this.$message.info('没有修改任何数据')
            }
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
</style>
